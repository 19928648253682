import React from 'react';
import styled from 'styled-components';


const Main = (props) => {

    const {text} = props;

    const BasicButton = styled.button`
      background-color: #be9874;
      color: white;
      margin: 10px 0;
      padding: 12px 40px 12px 40px;
      font-size: 13px;
      font-family: Roboto, sans-serif;
    `;


    return (
        <div className="col-xl-2 col-lg-2">
            <BasicButton>{text}</BasicButton>
        </div>
    )
}

export default Main;


