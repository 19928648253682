import {Link} from 'react-router-dom'
import React, {useState, useEffect, Suspense} from 'react'
import BGImg from "../../../assets/img/bg/bdrc-bg.jpg";
import {config} from "../../../Constants";
import {MdCoffee, MdWifi, MdDirectionsCar, MdAcUnit, MdApartment, MdOutlineLocalFlorist, MdLiquor} from "react-icons/md";


const Main = () => {
    let url = config.url.API_URL;
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [roomCategoryList, setRoomCategoryList] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/v1/room_category_images/`)
            .then(res => res.json())
            .then((data) => {
                    setIsLoaded(true);
                    setRoomCategoryList(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(true);
                }
            )
    }, [])

    return (
        <>
            <main>
                <section className="breadcrumb-area d-flex align-items-center" style={{background: `url(${BGImg})`}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-center">
                                    <div className="breadcrumb-title">
                                        <h2>Room Details</h2>
                                        <div className="breadcrumb-wrap">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Room
                                                        Details
                                                    </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="about-area5 about-p p-relative">
                    <div className="container pt-120 pb-40">
                        <Suspense fallback={<div>Loading ...</div>}>
                            {roomCategoryList.map((item, index) => (
                                <div className="col-lg-12 col-md-12 col-sm-12 order-1" key={index}>
                                    <div className="service-detail">
                                        <div className="two-column">
                                            <div className="row">
                                                <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                    <figure className="image"><img
                                                        src={item.image_1}
                                                        alt=""/></figure>
                                                </div>
                                                <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                    <figure className="image"><img
                                                         src={item.image_2}
                                                        alt=""/></figure>
                                                    <figure className="image"><img
                                                         src={item.image_3}
                                                        alt=""/></figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <div className="row align-items-center mb-50">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="price">
                                                        <h2>{item.room_category.title}</h2>
                                                        <span>{item.room_category.currency} {item.room_category.price.toLocaleString()}/Night</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{item.room_category.details}</p>
                                            <h3>Amenities include</h3>
                                            <tr>
                                                {item.room_category.has_garden_view ?
                                                    <td className="pl-20 pr-20"><MdOutlineLocalFlorist size={40}/> Garden View</td> : ""}
                                                {item.room_category.has_street_view ? <td className="pl-20 pr-20"><MdApartment size={40}/> Street View</td> : ""}
                                                {item.room_category.has_wifi ? <td className="pl-20 pr-20"><MdWifi size={40}/> Internet</td> : ""}
                                                {item.room_category.has_ac ? <td className="pl-20 pr-20"><MdAcUnit size={40}/> Air conditioning</td> : ""}
                                                {item.room_category.has_mini_bar ? <td className="pl-20 pr-20"><MdLiquor size={40}/> Mini Bar</td> : ""}
                                                {item.room_category.has_pickup_service ? <td className="pl-20 pr-20"><MdDirectionsCar size={40}/> Pickup service</td> : ""}
                                                {item.room_category.has_breakfast ? <td className="pl-20 pr-20"><MdCoffee size={40}/> Breakfast</td> : ""}
                                            </tr><br/>

                                            <div className="mb-50">
                                                <Link to="/bookings" className="btn ss-btn">Book This Room</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Main