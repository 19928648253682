import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Navbar from '../component/Navbar/Main';
import Footer from '../component/Footer/Main';
import Home from '../component/Home/Main';
import About from '../component/About/Main';
import RoomDetails from '../component/Rooms/RoomDetails/Main';
import Contact from '../component/Contact/Main';
import Bookings from '../component/Bookings/Main';
import ManageBookings from '../component/ManageBookings/Main';
import Availability from '../component/AvailableRoomCategories/Main';
import Registration from '../component/Clients/Registration/Main';
import Login from '../component/Auth/SignIn/Main';
import BackOffice from '../component/BackOffice/Main';


const Routing = () => {
    const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/login" || location.pathname === "/home-04" || location.pathname === "/back-office"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])
    
    const [footerpage, setfooterpage] = useState(false)
    useEffect(() => {
      if (location.pathname === "/login" || location.pathname === "/back-office"){
        setfooterpage(false)
      }else{
        setfooterpage(true)
      }
    }, [location])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {homepage && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/room-details" element={<RoomDetails />} />
                <Route path="/bookings" element={<Bookings />} />
                <Route path="/manage-bookings" element={<ManageBookings />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/availability" element={<Availability />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/login" element={<Login/>} />
                <Route path="/back-office" element={<BackOffice/>} />
            </Routes>
            {footerpage && <Footer />}
        </>
    )
}

export default Routing