import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Hotel from "../../../assets/img/bg/an-img-01.png";
import {config} from "../../../Constants";


const Main = () => {
    let url = config.url.API_URL;
    const location = useLocation();
    const {checkInDate, checkOutDate, adults, children, selectedRoomCategoryId, rooms} = location.state;
    const clientBookingUrl = `${url}/api/v1/create_client_and_booking/`;
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [inputs, setInputs] = useState({});

    const bookingPostBody = {
        first_name: inputs["firstName"],
        last_name: inputs["lastName"],
        phone_number: inputs["phoneNumber"],
        checkin_date: inputs["checkInDate"],
        checkout_date: inputs["checkOutDate"],
        adults: inputs["adults"],
        children: inputs["children"],
        rooms: inputs["rooms"],
        room_category_id: inputs["selectedRoomCategoryId"],
    }

    console.log(bookingPostBody);
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingPostBody)
    }

    const onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => ({...values, [name]: value,
            checkInDate:checkInDate,
            checkOutDate:checkOutDate,
            rooms:rooms,
            selectedRoomCategoryId: selectedRoomCategoryId,
            adults: adults, children:children}));

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(clientBookingUrl, requestMetadata)
            .then(res => res.json())
            .then((data) => {
                    setSubmissionStatus(true);
                    e.target.reset();
                },
                (error) => {
                    setSubmissionStatus(false);
                }
            )

    }

    return (
        <>
            <section className="booking pt-120 pb-120 p-relative fix">
                <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="contact-bg02">
                                <div className="section-title center-align">

                                    <h2>
                                        Booking Details
                                    </h2>
                                </div>
                                <form method="post" onSubmit={handleSubmit} className="contact-form mt-30">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <label><i className="fal fa-badge-check"></i>First Name</label>
                                                <input type="text" id="firstName" name="firstName"
                                                       onChange={onChange}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <label><i className="fal fa-times-octagon"></i>Last Name</label>
                                                <input type="text" id="lastName" name="lastName"
                                                       onChange={onChange}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <label><i className="fal fa-users"></i> Phone Number</label>
                                                <input type="phone" name="phoneNumber"
                                                       onChange={onChange}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="slider-btn mt-15">
                                                <button className="btn ss-btn" data-animation="fadeInRight"
                                                        data-delay=".8s" type="submit"><span>Book Room Now</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main
