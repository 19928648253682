import React from "react";
import ReactDOM from "react-dom";
import About from "../../../assets/img/bg/an-img-02.png";
import About1 from "../../../assets/img/features/about_img_02.png";
import About2 from "../../../assets/img/features/about_img_03.png";
import {Link} from "react-router-dom";
import About3 from "../../../assets/img/features/signature.png";
import AboutUs from "../../../assets/img/about-us/about-us-1.png";
import BGVideo from "../../../assets/img/bg/video-bg.png";


const Main = () => {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02"><img src={About} alt="contact-bg-an-02"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated"
                                 data-animation="fadeInLeft" data-delay=".4s">
                                <img src={BGVideo} alt="img"/>
                                <div className="about-icon">
                                    <img src={AboutUs} alt="img"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30"
                                 data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">
                                    <h5>About Us</h5>
                                    <h2>Top Rated Hotel in Dar.</h2>
                                </div>
                                <p>
                                    Welcome to City Inn, a modern and comfortable hotel situated in the heart of
                                    Kinondoni, Dar es Salaam. Our hotel features a variety of spacious and
                                    well-appointed rooms, equipped with all the amenities necessary for a relaxing
                                    and
                                    enjoyable stay. Whether you're traveling for business or leisure, our friendly
                                    and
                                    attentive staff will go above and beyond to ensure that your stay is as
                                    comfortable
                                    as possible.
                                </p>

                                <p>
                                    In addition to our comfortable rooms, we also offer a range of on-site amenities
                                    that are sure to make your stay with us even more pleasant. Our restaurant
                                    serves
                                    delicious meals made with fresh, locally sourced ingredients, while our bar
                                    offers a
                                    wide selection of refreshing beverages. Take a stroll through our beautiful
                                    garden
                                    and enjoy the serene surroundings, or catch up on work in our fully equipped
                                    business center.
                                </p>

                                <p>
                                    At City Inn, we are dedicated to making your visit to Dar es Salaam
                                    unforgettable.
                                    Our convenient location makes it easy to explore the city and all its
                                    attractions,
                                    including the National Museum of Tanzania, Kivukoni Fish Market, and the Dar es
                                    Salaam International Conference Centre. Book your stay with us today and
                                    experience
                                    the comfort and convenience of City Inn firsthand.
                                </p>

                                <div className="about-content3 mt-30">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-md-12">
                                            <ul className="green mb-30">
                                                <li> 24 hours/7 days a week</li>
                                                <li> Security, privacy and comfort</li>
                                                <li> Exceptional Customer service</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="slider-btn">
                                                <Link to="/about" className="btn ss-btn smoth-scroll">Discover
                                                    More</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <div className="signature">
                                                <img src={About3} alt="img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main;