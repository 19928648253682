import React, {Suspense, useEffect, useState} from "react";
import {config} from "../../Constants";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png"
import {AlertTitle, Box, Button, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {LinearProgress, TextField} from "@mui/material";
import {Modal, Form} from 'react-bootstrap';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip';
import Alert from "@mui/material/Alert";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const Main = (props) => {
    let url = config.url.API_URL;

    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const {checkInDate, checkOutDate, adults, children, rooms} = location.state;
    const [availableVacantRoomCategoryList, setAvailableVacantRoomCategory] = useState([]);
    const clientBookingUrl = `${url}/api/v1/create_client_and_booking/`;

    const [show, setShow] = useState(false);
    const [roomCategoryTitle, setRoomCategoryTitle] = useState(0);
    const [roomCategoryId, setRoomCategoryId] = useState(0);

    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [roomOccupancy, setRoomOccupancy] = useState({});
    const [bookings, setBookings] = useState([]);
    const [roomCount, setRoomCount] = useState(rooms);
    const [bookingLimit, setBookingLimit] = useState(true);

    const bookingPostBody = {
        checkInDate: checkInDate,
        checkOutDate: checkOutDate,
        adults: adults,
        children: children,
        rooms: rooms,
        roomOccupants: bookings

    }

    const handleShow = (roomCategoryId, roomCategoryTitle) => {
        setRoomCategoryId(roomCategoryId);
        setRoomCategoryTitle(roomCategoryTitle);

        setShow(true);
    }

    const handleClose = () => setShow(false);


    const onRoomOccupancyChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setRoomOccupancy(roomOccupancy => ({
            ...roomOccupancy, [name]: value, roomCategoryId: roomCategoryId, roomCategoryTitle: roomCategoryTitle
        }));

    }

    const handleSaveRoomOccupant = () => {

        if (roomCount == 0) {
            setBookingLimit(false);
        } else {
            setBookings(bookings => [...bookings, roomOccupancy])
            setRoomCount(roomCount - 1)
        }

        setShow(false);

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(clientBookingUrl, requestMetadata)
            .then(res => res.json())
            .then((data) => {
                    setSubmissionStatus(true);
                    setBookings([]);
                },
                (error) => {
                    setSubmissionStatus(false);
                }
            )

    }


    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingPostBody)
    }

    useEffect(() => {
        fetch(`${url}/api/v1/get_vacant_room_categories/`)
            .then(res => res.json())
            .then((data) => {
                setAvailableVacantRoomCategory(data);
                setIsLoaded(true);
            }, (error) => {
                setIsLoaded(true);
            })
    }, []);

    return (
        <>
            <section className="booking pt-120 pb-120 p-relative fix">
                <Container component="available-rooms" maxWidth="xs">
                    <Box sx={{
                        marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}>
                        <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="contact-bg02">
                                        <Typography component="h1" variant="h5">
                                            Available Rooms
                                        </Typography>

                                        <Box sx={{flexDirection: 'column'}}>
                                            <Typography variant="h6">
                                                Checkin Date: {checkInDate}
                                            </Typography>
                                            <Typography variant="h6">
                                                Checkout Date: {checkOutDate}
                                            </Typography>
                                            <Typography variant="h6">
                                                Number of Rooms Selected: {roomCount}
                                            </Typography>
                                        </Box>

                                        <Divider variant="middle">
                                            <Chip label="BOOKING CONFIRMATION SUMMARY"/>
                                        </Divider>

                                       {(submissionStatus) ? <Alert severity="success">Booking completed. Please check your email for the
                                            confirmation numbers.</Alert> :"" }
                                        <Box>
                                            {(bookingLimit) ? ("") : (
                                                <Alert onClose={() => {
                                                    setBookingLimit(true)
                                                }} severity="error">
                                                    <AlertTitle>Exceeded room number selected</AlertTitle>
                                                </Alert>)}

                                            <Grid container spacing={4} columns={12} marginTop={1} marginBottom={4}>
                                                <Grid item xs={2} sm={8}>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{minWidth: 650}} size="small"
                                                               aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">First Name</TableCell>
                                                                    <TableCell align="left">Last Name</TableCell>
                                                                    <TableCell align="left">Phone number</TableCell>
                                                                    <TableCell align="left">E-Mail</TableCell>
                                                                    <TableCell align="left">Selected room
                                                                        category</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {bookings.map((row) => (
                                                                    <TableRow
                                                                        key={row.firstName}
                                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                    >
                                                                        <TableCell align="left">
                                                                            {row.firstName}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {row.lastName}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="left">{row.phoneNumber}</TableCell>
                                                                        <TableCell
                                                                            align="left">{row.email}</TableCell>
                                                                        <TableCell
                                                                            align="left">{row.roomCategoryTitle}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>

                                                <Grid item xs={4} sm={3} style={{marginLeft: "5px"}}>
                                                    <a onClick={handleSubmit} type="button"
                                                       className="top-btn mt-7 mb-10">Click to Proceed to checkout</a>
                                                </Grid>

                                            </Grid>

                                        </Box>

                                        <Divider variant="middle">
                                            <Chip className="primary"
                                                  label="Select your rooms and add occupants"></Chip>
                                        </Divider>

                                        {(!isLoaded) ? (<LinearProgress/>) :
                                            (<Suspense fallback={<div>Loading...</div>}>
                                                {availableVacantRoomCategoryList.map((item, index) => (
                                                    <Grid key={index} container columnSpacing={2} rowSpacing={4}
                                                          columns={12}
                                                          marginTop={2} paddingBottom={6}>
                                                        <Grid item xs={3} md={2}>
                                                            <img src={Hotel1}/>
                                                        </Grid>

                                                        <Grid item xs={9} md={4}>
                                                            <Typography variant="h4" component="h4">
                                                                {item.title}
                                                            </Typography>
                                                            <Typography variant="h6" component="h4">
                                                                {item.currency} {item.price.toLocaleString()}
                                                            </Typography>
                                                            <Typography variant="h6" component="h4">
                                                                {item.details}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={4} md={2}>
                                                            <Typography variant="h5">
                                                                {"Rooms Left: " + item.room_count}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={7} sm={3} style={{marginLeft: "5px"}}>
                                                            <div>
                                                                <a onClick={() => handleShow(item.id, item.title)}
                                                                   type="button"
                                                                   className="top-btn mt-7 mb-10">Add Room Occupant</a>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                ))}
                                            </Suspense>)
                                        }

                                        <Modal show={show}>
                                            <Modal.Header closeButton onClick={handleClose}>
                                                <Modal.Title>Booking Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <>
                                                    <form method="post" onSubmit={handleSubmit}
                                                          className="contact-form mt-30">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="contact-field p-relative c-name mb-20">
                                                                    <label><i className="fal fa-user"></i> First
                                                                        Name</label>
                                                                    <input type="text" id="firstName" name="firstName"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12">
                                                                <div
                                                                    className="contact-field p-relative c-subject mb-20">
                                                                    <label><i className="fal fa-user"></i> Last
                                                                        Name</label>
                                                                    <input type="text" id="lastName" name="lastName"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div
                                                                    className="contact-field p-relative c-subject mb-20">
                                                                    <label><i className="fal fa-phone"></i> Phone
                                                                        Number</label>
                                                                    <input type="phone" name="phoneNumber"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div
                                                                    className="contact-field p-relative c-subject mb-20">
                                                                    <label><i className="fal fa-mailbox"></i> e-Mail
                                                                    </label>
                                                                    <input type="email" name="email"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div
                                                                    className="col-xl-12 col-lg-12 d-none d-lg-block mt-4">
                                                                    <a onClick={handleSaveRoomOccupant} type="button"
                                                                       className="top-btn mt-7 mb-10">Save Room
                                                                        Occupant</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="contained" color="error"
                                                        onClick={handleClose}>Close</Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Container>
            </section>
        </>
    )

}

export default Main;